import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../layouts/Layout"
import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"

const AboutPage = ({ data }) => (
  <Layout>
    <SiteMetadata title="About" description="Sample description" />

    <div className="bg-gray-800">
      <div className="container py-12 lg:pb-16">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 xl:w-3/5 pb-8 md:pb-0">
            <h1 className="text-3xl font-extrabold text-gray-300 sm:text-4xl">
              <span className="">About me</span>
            </h1>

            <p className=" text-gray-400">
            I've always been passionately curious about technology, design and human behavior from a young age. This naturally led me to a career in Product Management. For the last 9 years, I have worked alongside many companies and clients from small startups through to Fortune 50 companies. I've been fortunate to have the opportunity to wear many hats and make contributions from all angles of the SDLC, from design to writing production-ready code. At the end of the day, I like building things to enrich the lives of people doing everyday tasks by a deeper understanding of their problems and environment.
            </p>
            <div className="mt-4 text-gray-500 leading-loose">
              <br />
              <br />
              <br />
              <br />
              I'm always happy to hear from you:
              <br />
              <a
                href="mailto:hello@coreykellgren.com"
                className="border-b border-gray-500 hover:border-blue-600 hover:text-blue-600"
              >
                hello@coreykellgren.com
              </a>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-2/5 md:pl-12">
            {/* <Img
              fluid={data.author.childImageSharp.fluid}
              alt="Corey Kellgren"
              className="rounded-md shadow-md"
            /> */}
          </div>
        </div>
      </div>
    </div>
    <Newsletter />
  </Layout>
)

export default AboutPage

export const query = graphql`
  query {
    author: file(relativePath: { eq: "author.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 680, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
